.radio-btn {
  width: 55px;
  height: 24px;
  padding: 1px;
  background-color: #4731d3;
  border-radius: 100px;
  overflow: hidden;
  cursor: pointer;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  transition: all 1s;
}

.dark .radio-btn {
  background-color: #3a3a3a;
  transition: all 1s;
}
.radio-btn .radio-inner {
  position: relative;
  width: 22px;
  height: 22px;
  background-color: #ffe86e;
  border-radius: 50%;
  transition: all 1s;
}

.radio-btn .radio-inner::before {
  content: "";
  position: absolute;
  top: 0px;
  left: -51%;
  width: 100%;
  height: 100%;
  background-color: #e92577;
  border-radius: 50%;
  transform: scale(0);
  transition: all 1s;
}

.radio-btn .radio-inner.active {
  transform: translateX(calc(55px - 20px));
}
.radio-btn .radio-inner.active::before {
  background-color: #3a3a3a;
  left: -10px;
  transform: scale(1);
}

.hireButton {
  @apply flex items-center justify-between py-[12px] px-[32px] border text-[#3730a3] border-[#3730a3]  hover:text-white  hover:bg-[#3730a3] dark:text-black hover:dark:text-white hover:dark:bg-[#767676] dark:border-[#ffffff] dark:bg-[#E1E1FF]  hover:dark:border-[#767676] rounded-md duration-1000;
}

.pContainer {
  @apply mt-8 pb-32 flex flex-wrap max-[640px]:flex-col gap-8;
  perspective: 1200px;
}

.pItem {
  @apply flex-[1_0_30%] box-border max-[640px]:max-w-full p-3;
}
